import PropTypes from "prop-types";

import { PutAShadeOnIt } from "./put-a-shade-on-it";
import { ConditionalWrapper } from "~/shared-components/story-card/_utilities/components";

import {
  getRenderedContentId,
  reconcileSuppressDuplicates
} from "~/components/contexts/rendered-content-context.helpers";

import {
  isFeatureHiddenOnDesktop,
  isFeatureHiddenOnMobile
} from "~/shared-components/story-card/_utilities/helpers";

const reconcileHideShow = (
  { desktopInfo = {}, mobileInfo = {} },
  featureHides,
  chainHides
) => {
  const showOnDesktop = !(
    desktopInfo.isSuppressibleDuplicate ||
    featureHides.hideOnDesktop ||
    chainHides.hideOnDesktop
  );

  const showOnMobile = !(
    mobileInfo.isSuppressibleDuplicate ||
    featureHides.hideOnMobile ||
    chainHides.hideOnMobile
  );
  const showOnDesktopOrMobile = showOnDesktop || showOnMobile;

  return { showOnDesktopOrMobile, showOnDesktop, showOnMobile };
};

// NOTE: Keep the form in sync with return value of checkIsSuppressibleDuplicate
export const INIT_DEDUPE_INFO = {
  isDuplicate: false,
  isSuppressibleDuplicate: false,
  desktopInfo: { isDuplicate: false, isSuppressibleDuplicate: false },
  mobileInfo: { isDuplicate: false, isSuppressibleDuplicate: false }
};

/**
 * Taking various factors into account, this function determines whether content should be
 * considered a suppressible duplicate or not. First factor is, has this content (judging by
 * renderedContentId) appeared on the page before. Then, has it it been told to suppress duplicates
 * or not.
 *
 * @param {renderedContent} object -- For tracking dedupes. In normal use, it should come from
 * useRenderedContentContext(). The keys in the map are the renderedContentId and the values are,
 * among other things, an object with a list of featureIds where that content appears.
 * @param {suppressDuplicates} boolean -- In normal use, it should come from useChainContext()
 * @param {renderedContentId} string -- dedup id of content. If ans content, highly recommended to use
 * fusion_additions.links_to_use.basic. It can be any unique identifier.
 * @param {featureId} string -- id of the feature in which the content appears. Recommended to use
 * fingerprint id.
 *
 * @returns {obj} -- Object shaped like INIT_DEDUPE_INFO
 */
export const checkIsSuppressibleDuplicate = ({
  outputType,
  renderedContent = {},
  rootCustomFields,
  chainOverrides,
  content,
  curationIndices,
  // featureId,
  overrides = {}
}) => {
  // NOTE: feature-level show/hide
  const featureHides = {
    hideOnDesktop: isFeatureHiddenOnDesktop(overrides, outputType),
    hideOnMobile: isFeatureHiddenOnMobile(overrides, outputType)
  };

  // NOTE: chain-level show/hide
  const chainHides = {
    hideOnDesktop: !!chainOverrides?.hideFromDesktop,
    hideOnMobile: !!chainOverrides?.hideFromMobile
  };

  const suppressDuplicates = reconcileSuppressDuplicates(
    overrides,
    chainOverrides,
    rootCustomFields
  );

  const renderedContentId = getRenderedContentId({ content, overrides });

  let desktopInfo = { isDuplicate: false, isSuppressibleDuplicate: false };
  let mobileInfo = { isDuplicate: false, isSuppressibleDuplicate: false };

  if (renderedContentId && curationIndices?.rank) {
    [desktopInfo, mobileInfo] = Object.keys(featureHides).map((hideKey) => {
      let isDuplicate = false;
      let isSuppressibleDuplicate = false;
      if (!(featureHides[hideKey] || chainHides[hideKey])) {
        const cacheItem = renderedContent[renderedContentId];
        const lowestRank =
          cacheItem?.lowestRanks?.[
            /mobile/i.test(hideKey) ? "mobile" : "desktop"
          ];
        /*
        const featureIds = (cacheItem?.featureIds || []).filter(
          (id) => !cacheItem[id][hideKey]
        );
        */

        // if (featureIds.length && featureIds.indexOf(featureId) !== 0) {
        if (
          typeof lowestRank !== "undefined" &&
          curationIndices.rank > lowestRank
        ) {
          isDuplicate = true;
          isSuppressibleDuplicate = !!suppressDuplicates && !!isDuplicate;
        }
      }
      return { isDuplicate, isSuppressibleDuplicate };
    });
  }

  // TODO: In the end, these two should be deprecated. While working on jsonapp feed/ff,
  // they are used by web/carousel. It is not very well-behaved when there's a mix of hide/show on desktop/mobile
  const isDuplicate = desktopInfo.isDuplicate && mobileInfo.isDuplicate;
  const isSuppressibleDuplicate =
    desktopInfo.isSuppressibleDuplicate && mobileInfo.isSuppressibleDuplicate;

  // NOTE: Keep the form in sync with INIT_DEDUP_INFO
  const info = {
    isDuplicate,
    isSuppressibleDuplicate,
    desktopInfo,
    mobileInfo
  };

  return {
    ...info,
    ...reconcileHideShow(info, featureHides, chainHides)
  };
};

export const HandleSuppressibleDuplicateInAdmin = ({
  isAdmin = false,
  isSuppressibleDuplicate = false,
  children
}) => {
  return (
    <ConditionalWrapper
      condition={isAdmin && isSuppressibleDuplicate}
      wrapper={(theseChildren) => (
        <PutAShadeOnIt text="duplicate content" color="orange">
          {theseChildren}
        </PutAShadeOnIt>
      )}
    >
      {children}
    </ConditionalWrapper>
  );
};

HandleSuppressibleDuplicateInAdmin.propTypes = {
  isAdmin: PropTypes.boolean,
  isSuppressibleDuplicate: PropTypes.boolean,
  children: PropTypes.any
};
