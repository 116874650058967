import PropTypes from "prop-types";
import { getClasses } from "@washingtonpost/front-end-utils";
import { useAssemblerContext } from "~/shared-components/AssemblerContext";

import { getAspectRatioClasses } from "../_utilities/helpers";

// NOTE: These vars are used by the slideshow to adjust the height of the captions.
// If we ever change the caption font size/style, these would also need to change.
export const CAPTION_LINE_HEIGHT = 13; // empirically derived
export const CAPTION_PX_PER_CHAR = 5.2; // empirial estimate

// NOTE: There is an edge-case where the Slideshow needs these
export const getCaptionClasses = ({ aspectRatio = 1.5, tag }) =>
  getClasses("gray-dark font-xxxxs left mt-xs font--meta-text lh-sm", {
    [getAspectRatioClasses(aspectRatio)]: aspectRatio,
    "mt-xs": tag === "figcaption"
  });

const Caption = ({
  aspectRatio,
  caption,
  forbiddenButUnstoppable,
  notPublishable,
  path,
  style = {},
  tag = "div"
}) => {
  const { EditableProperty } = useAssemblerContext();
  if (typeof caption === "undefined") return null;

  if (forbiddenButUnstoppable) {
    style = { ...style, color: "white", backgroundColor: "red" };
  } else if (notPublishable) {
    style = { ...style, color: "black", backgroundColor: "yellow" };
  }

  return (
    <EditableProperty
      className={getCaptionClasses({
        aspectRatio,
        tag
      })}
      path={path}
      style={style}
      tag={tag}
      value={caption}
    />
  );
};

Caption.propTypes = {
  aspectRatio: PropTypes.number,
  caption: PropTypes.string,
  forbiddenButUnstoppable: PropTypes.bool,
  notPublishable: PropTypes.bool,
  path: PropTypes.string,
  style: PropTypes.object,
  tag: PropTypes.string
};

export default Caption;
