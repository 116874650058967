import { Fragment, useRef, useEffect, useState } from "react";
import ContentLoader from "react-content-loader";
import PropTypes from "prop-types";
import get from "lodash.get";
import { styled, theme, Icon } from "@washingtonpost/wpds-ui-kit";
import { getClasses } from "@washingtonpost/front-end-utils";
import getResizedUrl from "~/components/core/elements/image/resize-url";
import WapoLazyChild from "~/shared-components/WapoLazyChild";
import { useSpartanFeature } from "~/src/content";
import { useSandwichLayoutContext } from "~/shared-components/layouts/SandwichLayoutContext";
import { useBreakpoints } from "~/shared-components/BreakpointContext";
import { useFeatureLayoutContext } from "~/shared-components/FeatureLayoutContext";
import { useChainContext } from "~/shared-components/ChainContext";
import { useAssemblerContext } from "~/shared-components/AssemblerContext";
import { IMPLICIT_GRID_INFO } from "~/components/layouts/utilities/grid-helpers";
import { getAspectRatioClasses } from "../_utilities/helpers";
import { ConditionalWrapper } from "../_utilities/components";
import Warning from "~/shared-components/Warning";
import Caption from "./Caption";
import CustomHTML from "./CustomHTML";
import { iconsComponents } from "./Icons";
import { getArtBreakpointSizes, getWidthAndHeight } from "./Image.helpers";
import getImageToolbarItems from "./Image.toolbar";

const StyledWrapper = styled("div", {
  color: theme.colors.onCta
});

const ArtWrapper = ({ hasCaption = false, ...props }) => {
  const Tag = hasCaption ? "figure" : "div";

  return <Tag data-qa="art-wrapper" {...props} />;
};

ArtWrapper.propTypes = {
  hasCaption: PropTypes.bool
};

const wrapperClasses = "w-100 mw-100 overflow-hidden relative pb-xs";

export const ArtOverlay = ({
  text,
  style,
  secondaryText,
  secondaryStyle,
  prefixMedia,
  prefixIcon,
  suffixIcon
}) => {
  if (!text && !secondaryText) return null;

  let prefixImage;
  if (prefixMedia?.url) {
    prefixImage = (
      <img
        src={prefixMedia.url}
        width="16"
        height="16"
        className="brad-50 mr-xs relative"
        style={{ top: "4px" }}
      />
    );
  }

  prefixIcon = iconsComponents[prefixIcon];
  suffixIcon = iconsComponents[suffixIcon];

  const PrefixIcon = prefixIcon?.small;
  const SuffixIcon = suffixIcon?.small;

  const bgColor = /live/.test(secondaryStyle) ? "bg-red" : "bg-black";

  const className = getClasses(
    `absolute left-0 bottom-0 left pa-sm bold lh-sm antialiased ao-btn ${bgColor}`,
    {
      "ma-xs pa-sm brad-20": !/compact/.test(style)
    }
  );

  return (
    <StyledWrapper className={className}>
      {prefixImage}
      {!prefixImage && prefixIcon && prefixIcon.small && (
        <Icon
          size="$100"
          width="16"
          height="16"
          fill="white"
          className="prefix"
        >
          <PrefixIcon />
        </Icon>
      )}
      {text}
      {secondaryText && (
        <span
          className={getClasses("", {
            "ml-xs": text,
            "font-light": secondaryStyle !== "secondary_live"
          })}
        >
          {secondaryText}
        </span>
      )}
      {suffixIcon && suffixIcon.small && (
        <Icon
          size="$100"
          width="16"
          height="16"
          fill="white"
          className="prefix"
        >
          <SuffixIcon />
        </Icon>
      )}
    </StyledWrapper>
  );
};

ArtOverlay.propTypes = {
  text: PropTypes.string,
  style: PropTypes.string,
  secondaryText: PropTypes.string,
  secondaryStyle: PropTypes.string,
  prefixMedia: PropTypes.object,
  prefixIcon: PropTypes.string,
  suffixIcon: PropTypes.string
};

const isInViewport = (elem) => {
  if (!elem) return false;
  const bounding = elem.getBoundingClientRect();
  return (
    typeof window !== "undefined" &&
    bounding.top >= -200 &&
    bounding.left >= 0 &&
    bounding.bottom <=
      (window.innerHeight || document.documentElement.clientHeight) + 200 &&
    bounding.right <=
      (window.innerWidth || document.documentElement.clientWidth)
  );
};

const Image = (props) => {
  const {
    altText,
    artWidth,
    aspectRatio,
    useHiRes,
    makeItRound,
    warning,
    caption,
    customhtml,
    lazyLoad,
    linkUrl,
    overlay,
    url
  } = props;
  const breakpoints = useBreakpoints();
  const layoutCtx = useSandwichLayoutContext();
  const featureLayoutCtx = useFeatureLayoutContext();
  const layoutVars = {
    ...(layoutCtx?.layoutVars || {}),
    ...(featureLayoutCtx?.layoutVars || {})
  };
  const { useDesktopOrdering } = useChainContext();

  const [isVisible, setIsVisible] = useState(false);
  const { EditableArt } = useAssemblerContext();
  const divRef = useRef();

  useEffect(() => {
    let isMounted = true;
    const timeout = setTimeout(() => {
      if (isMounted && !isVisible && isInViewport(divRef.current)) {
        setIsVisible(true);
      }
    }, 500);

    return () => {
      isMounted = false;
      clearTimeout(timeout);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const { props: otherProps = {} } = useSpartanFeature();
  const { customFields: rawProps = {} } = otherProps;

  const hasCaption = !customhtml && !!caption?.caption;

  if (!url && !customhtml) {
    return null;
  }

  const isFullWidth = artWidth === "full-width";

  const bp = breakpoints.bp || IMPLICIT_GRID_INFO[0].bp;
  // const bp = breakpoints.bp || "mx";
  const colspan = get(layoutVars, `--c-span-${bp}`);

  // START: The equivalent of this code was once in the flex feature
  // Now, it's closer to the actual rendering where it probably belongs
  const dataSpans = IMPLICIT_GRID_INFO.reduce((acc, info) => {
    acc[info.bp] = get(layoutVars, `--c-span-${info.bp}`);
    return acc;
  }, {});
  const imageBreakpointSizes = getArtBreakpointSizes(dataSpans, artWidth);
  // END: This code was once in the flex feature

  const { width, height } = getWidthAndHeight(
    colspan,
    imageBreakpointSizes[bp],
    aspectRatio,
    isFullWidth
  );
  const widthStyle = { width: "100%", height: "100%" };

  // set the image at the exact height and width
  // const resized4xUrl = getResizedUrl(url, { w: 4 * width, h: 4 * height });
  const resized2xUrl = getResizedUrl(url, { w: 2 * width, h: 2 * height });
  const resizedUrl = useHiRes
    ? resized2xUrl
    : getResizedUrl(url, { w: width, h: height });

  const className = getClasses("border-box db", {
    "brad-50": makeItRound
  });

  const imgTag = (
    <img
      style={widthStyle}
      srcSet={`${resized2xUrl} 2x`}
      src={resizedUrl}
      alt={altText || ""}
      width={width}
      height={height}
      className={className}
    />
  );

  const main = !customhtml ? imgTag : <CustomHTML {...customhtml} />;

  const placeholder = !customhtml ? (
    <ContentLoader
      animate={false}
      viewBox={`0 0 ${width} ${height}`}
      foregroundColor={theme.colors.gray400}
      backgroundColor={theme.colors.gray400}
      uniqueKey={`ImageLoader-${width}x${height}`}
    >
      <rect x="0" y="0" width={width} height={height} />
    </ContentLoader>
  ) : (
    imgTag
  );

  const toolbar = getImageToolbarItems({
    ...props,
    rawProps,
    useDesktopOrdering
  });

  // NOTE: isArtTinyMode is NOT based on the "Tiny" artSize, but rather
  // on the calcuated width of the art
  const isArtTinyMode = width < 3 * 34 + 2 * 32; // 3-col

  return (
    <Fragment>
      <ArtWrapper
        hasCaption={hasCaption}
        className={getClasses(wrapperClasses, {
          "lh-0": !customhtml,
          "lh-1": customhtml,
          [getAspectRatioClasses(aspectRatio)]: !customhtml && aspectRatio
        })}
      >
        {warning && !isArtTinyMode && (
          <div>
            <Warning message={warning} level="warning" />
          </div>
        )}
        <div
          className={getClasses("dib relative", { "brad-50": makeItRound })}
          style={widthStyle}
          ref={divRef}
        >
          <ConditionalWrapper
            condition={!customhtml && linkUrl}
            wrapper={(children) => (
              <a href={linkUrl} className="art-link">
                {children}
              </a>
            )}
          >
            <EditableArt menuItems={toolbar}>
              <WapoLazyChild
                eager={!lazyLoad || isVisible}
                offsetTop={0}
                offsetBottom={0}
                throttle={25}
                {...(lazyLoad
                  ? {
                      renderPlaceholder: (ref) => (
                        <div ref={ref}>{placeholder}</div>
                      )
                    }
                  : {})}
              >
                {main}
              </WapoLazyChild>
            </EditableArt>
            {!customhtml && overlay && <ArtOverlay {...overlay} />}
          </ConditionalWrapper>
        </div>
        {hasCaption && <Caption tag="figcaption" {...caption} />}
      </ArtWrapper>
    </Fragment>
  );
};

Image.propTypes = {
  altText: PropTypes.string,
  artWidth: PropTypes.string,
  aspectRatio: PropTypes.number,
  useHiRes: PropTypes.boolean,
  makeItRound: PropTypes.boolean,
  warning: PropTypes.string,
  caption: PropTypes.object,
  lazyLoad: PropTypes.boolean,
  linkUrl: PropTypes.string,
  overlay: PropTypes.object,
  url: PropTypes.string,
  customhtml: PropTypes.object
};

export default Image;
