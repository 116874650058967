import PropTypes from "prop-types";

const DEFAULT_COLUMN_CLASSES =
  "hpgrid-item hpgrid-item--c-start hpgrid-item--c-spans hpgrid-item--r-spans";

export const PutAShadeOnIt = ({
  layoutClasses = "",
  layoutStyles = {},
  positionClasses = "",
  text = "put it under a shade",
  color = "lightgray",
  children
}) => {
  return (
    <div
      className={`card relative ${
        layoutClasses || DEFAULT_COLUMN_CLASSES
      } ${positionClasses}
      `}
      style={layoutStyles}
    >
      <div
        className="w-100 font-bold pointer absolute truncate"
        style={{
          backgroundColor: color,
          lineHeight: 1,
          top: "10px",
          left: "10px",
          zIndex: 1200,
          width: "fit-content"
        }}
      >
        {/* {hideChildren ? `\u2295 ` : `\u2296 `} */}
        {text}
      </div>
      <div className={`o-50 bg-white`}>{children}</div>
    </div>
  );
};

PutAShadeOnIt.propTypes = {
  text: PropTypes.string,
  color: PropTypes.string,
  layoutClasses: PropTypes.string,
  layoutStyles: PropTypes.object,
  positionClasses: PropTypes.string,
  children: PropTypes.any
};
