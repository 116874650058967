/* React */
import { Fragment, useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import dynamic from "next/dynamic";

import merge from "lodash.merge";
import get from "lodash.get";

/* Utilities */
import { getClasses } from "@washingtonpost/front-end-utils";
import { useBreakpoints } from "~/shared-components/BreakpointContext";
import { getSubComponentArrangements } from "../_utilities/helpers";
import { convertArtSize } from "../_utilities/style-helpers";

/* Components */
import { ConditionalWrapper } from "../_utilities/components";
import TopperLabel from "./TopperLabel";
import { getTopperLabel } from "./TopperLabel.helpers";
import { CompoundLabel } from "./Label";
import { getCompoundLabel } from "./Label.helpers";
import { getCallToAction } from "./CallToAction.helpers";
import { getCount } from "./Count.helpers";
import { getExcerpt } from "./Excerpt.helpers";
import { getFootnote } from "./Footnote.helpers";
import Headline from "./Headline";
import { getHeadline } from "./Headline.helpers.js";
import { MultiBlurb } from "./Blurb";
import { getMultiBlurb } from "./Blurb.helpers.js";
import Sigline from "./Sigline";
import { getSigline } from "./Sigline.helpers.js";
import Recipe from "./Recipe";
import { getRecipe } from "./Recipe.helpers.js";
import RelatedLinks from "./RelatedLinks";
import { getRelatedLinks } from "./RelatedLinks.helpers.js";
import { getLiveTicker } from "./LiveTicker.helpers.js";
import Image from "./Image";
import { getImage } from "./Image.helpers.js";
import { getCaption } from "./Caption.helpers.js";
import { getAudio } from "./Audio.helpers.js";
import { getElection } from "./Election.helpers.js";
import { getOlympics } from "./Olympics.helpers.js";
import { getSlideshow } from "./Slideshow.helpers.js";
// NOTE: Video does not yet follow the established pattern
// of <Component />/getComponent() i.e <Video />/getVideo(). Some day!
import { getVideoData } from "./Video.helpers.js";

const CallToAction = dynamic(() => import("./CallToAction"));
const VideoFlex = dynamic(() => import("./Video"));
const Slideshow = dynamic(() => import("./Slideshow"));
const Olympics = dynamic(() => import("./Olympics"));
const Election = dynamic(() => import("./Election"));
const Audio = dynamic(() => import("./Audio"));
const LiveTicker = dynamic(() => import("./LiveTicker"));
const Count = dynamic(() => import("./Count"));
const Footnote = dynamic(() => import("./Footnote"));
const Excerpt = dynamic(() => import("./Excerpt"));

/**
 * @param {object} arrangment - The arrangement object
 * @param {string} convertedArtSize - a size like xxl or mini, etc.
 * @param {bool} wrapText - whether wrapText is turned on
 * @param {bool} allowValign - whether text is valigned
 * @returns a style object with css vars. creates up to three vars (see below) based on the props passed in.
 *    --w-art - as long as the art is not full width (i.e. either left or right), set it to --w-${size}
 *      which are defined in grid-css.scss
 *    --w-txt - if text wrapping is off, this should be defined
 *    --w-dib - only needed if text wrapping is on. this is potentially used by other subcomponents. for now,
 *      it's only needed by the secondary label
 *
 *  NOTE: There are currently only width vars, but there could be any kind of css var
 */
const getCardStyleVars = ({
  arrangement,
  convertedArtSize,
  wrapText,
  allowValign
}) => {
  const vars = {};
  if (convertedArtSize !== "fullWidth") {
    vars["--w-art"] = `var(--w-${convertedArtSize})`;
    const artIsLeft = !!get(arrangement, "left", {}).hasOwnProperty("width");
    const wTxt = `var(--w-${convertedArtSize}-t)`;
    const wrapTextOverride = artIsLeft ? false : wrapText;
    if (!wrapTextOverride || allowValign) {
      vars["--w-txt"] = wTxt;
    } else {
      vars["--w-dib"] = wTxt;
    }
  }
  return vars;
};

/**
 * @param {string} functionalZone - basically art or txt
 * @param {object} cardStyleVars - the object returned by getCardStyleVars()
 * @returns a style object with that uses the css vars to set actual css properties. currently, it
 *   sets widths on the art and text zones as appropriate
 */
const getZoneStyleVars = ({ functionalZone, cardStyleVars }) => {
  const style = {};
  const wZone = cardStyleVars?.[`--w-${functionalZone}`];
  if (wZone) style.width = wZone;
  if (functionalZone === "txt") {
    const wDib = cardStyleVars?.["--w-dib"];
    if (wDib) style["--w-dib"] = wDib;
  }
  return { style };
};

/**
 * @param {string} view Name of the view, used to pass the semantic markup along.
 * @param {object} data Filtered ANS object.
 * @param {string} classes String of classes
 * @param {object} overrides
 * @returns The markup associated with the view -- this is the secret sauce.
 */
export const Markup = ({
  json,
  overrides,
  className,
  style = {},
  attrs = {}
}) => {
  const ref = useRef();
  const components = {
    /* eslint-disable react/display-name */
    Audio: (data) => (!data ? null : <Audio {...data} />),
    Blurb: (data) => (!data ? null : <MultiBlurb {...data} />),
    CallToAction: (data) => (!data ? null : <CallToAction {...data} />),
    Count: (data) => (!data ? null : <Count {...data} />),
    Excerpt: (data) => (!data ? null : <Excerpt {...data} />),
    Footnote: (data) => (!data ? null : <Footnote {...data} />),
    Headline: (data) => (!data ? null : <Headline {...data} />),
    Election: (data) => (!data ? null : <Election {...data} />),
    Olympics: (data) => (!data ? null : <Olympics {...data} />),
    TopperLabel: (data) => (!data ? null : <TopperLabel {...data} />),
    Video: (data) => {
      const { videoData, isAdmin, caption, overrides: rest } = data;
      return (
        <VideoFlex
          videoData={videoData}
          caption={caption}
          {...rest}
          isAdmin={isAdmin}
        />
      );
    },
    Image: (data) => (!data ? null : <Image {...data} />),
    Label: (data) => (!data ? null : <CompoundLabel {...data} />),
    Slideshow: (data) => (!data ? null : <Slideshow {...data} />),
    Recipe: (data) => (!data ? null : <Recipe {...data} />),
    RelatedLinks: (data) => (!data ? null : <RelatedLinks {...data} />),
    LiveTicker: (data) => (!data ? null : <LiveTicker {...data} />),
    Sigline: (data) => (!data ? null : <Sigline {...data} />)
    /* eslint-enable react/display-name */
  };

  const { arrangement } = json || {};
  const { bp = "mx" } = useBreakpoints();

  const sidebarItems = get(arrangement, "sidebar.items", []);
  const hasSidebar = !!sidebarItems.length;

  // START: className
  const {
    wrapText,
    textAlignment,
    textVerticalAlignment,
    artPosition = ""
  } = overrides;

  const [isTextTaller, setTextTaller] = useState(false);
  const allowValign = /center/.test(textVerticalAlignment);
  const valign = allowValign && !isTextTaller;

  // SETH: This is more tightly coupled than I would like... Should revisit
  const convertedArtSize = convertArtSize(overrides);
  const noArt = typeof json.Art === "undefined";
  // NOTE: Only allow wrapText to work when art is right-aligned and not valign center
  className = getClasses(className, {
    "has-sidebar": hasSidebar,
    [`${textAlignment === "center" ? "center" : "left"}`]: true,
    [`${
      wrapText && /right/i.test(artPosition) && !valign ? "" : "no-"
    }wrap-text`]: true,
    [`art-size--${convertedArtSize}`]: !!convertedArtSize && !noArt
  }).trim();
  // END: className

  const classNameValign = getClasses("", {
    "valign-center relative": valign
  }).trim();

  // START: attrs
  attrs = {
    "data-link-detail": get(json, "Count.count"),
    ...attrs
  };
  // END: attrs

  // TODO: In the admin, somehow make valign respond to changes to custom-fields/overrides/json cuz
  // many different changes could potentially make the text size shorter/taller than the art side,
  // leading to the need to toggle valign.
  // const cacheBuster = ref.current;
  useEffect(() => {
    if (allowValign) {
      const artHeight = ref?.current?.querySelector(".card-art")?.clientHeight;
      const textHeight = ref?.current?.querySelector(
        ".card-text.next-to-art"
      )?.clientHeight;
      if (artHeight && textHeight) setTextTaller(textHeight > artHeight);
    }
  }, [bp, allowValign]);
  // }, [cacheBuster]);

  if (!arrangement) return null;

  const cardStyleVars = getCardStyleVars({
    arrangement,
    convertedArtSize,
    wrapText,
    allowValign
  });

  const MainMarkup = () => {
    const zones = Object.keys(arrangement)
      .filter((key) => key !== "sidebar")
      .reduce((acc, key) => {
        const items = get(arrangement, `${key}.items`, []);
        // NOTE: Only the left/right positions that have the art slot will have a width
        const isArtSlot = get(arrangement, key, {}).hasOwnProperty("width");
        const isNextToArt = !isArtSlot && key.match(/left|right/);
        acc[key] = (
          <Fragment key={key}>
            {items.length > 0 && (
              <div
                className={getClasses(
                  `card-${key} ${
                    isArtSlot ? `card-art left art--${key}` : "card-text"
                  }`,
                  {
                    "next-to-art": isNextToArt,
                    fl:
                      key.match(/left/) &&
                      overrides.artPosition.toLowerCase().match(/left/),
                    cb: !valign && key === "bottom",
                    "no-bottom":
                      key.match(/left|right/) &&
                      !Object.keys(arrangement).includes("bottom")
                  }
                )}
                {...getZoneStyleVars({
                  cardStyleVars,
                  functionalZone: isArtSlot
                    ? "art"
                    : `${isNextToArt ? "txt" : ""}`
                })}
              >
                {items.map((component, idx) => {
                  const data = json[component];
                  // NOTE: Use the markupComponent if present
                  // TODO: Clean-up eventually as part of the rethink getArtSlotInfo project
                  const componentToUse = get(
                    data,
                    "markupComponent",
                    component
                  );
                  return (
                    <Fragment key={`${componentToUse}-${idx}`}>
                      {components[componentToUse](data)}
                    </Fragment>
                  );
                })}
              </div>
            )}
          </Fragment>
        );
        return acc;
      }, {});

    return (
      <div
        ref={ref}
        className={className}
        data-feature-id="homepage/story"
        style={style}
        {...attrs}
      >
        {zones.top}
        <ConditionalWrapper
          condition={valign && zones.left && zones.right}
          wrapper={(children) => (
            <div className={classNameValign}>{children}</div>
          )}
        >
          {zones.right}
          {zones.left}
        </ConditionalWrapper>
        {zones.bottom}
        {!valign && !zones.bottom && (!!zones.right || !!zones.left) && (
          <div className="cb" />
        )}
      </div>
    );
  };

  return (
    <ConditionalWrapper
      condition={hasSidebar}
      wrapper={(children) => (
        <div className="flex flex-row">
          <div className="card-sidebar">
            {sidebarItems.map((component, idx) => {
              const data = json[component];
              return (
                <Fragment key={`${component}-${idx}`}>
                  {components[component](data)}
                </Fragment>
              );
            })}
          </div>
          {children}
        </div>
      )}
    >
      {/* NOTE: if <MainMarkup />, content editable breaks in admin */}
      {MainMarkup()}
    </ConditionalWrapper>
  );
};

Markup.propTypes = {
  json: PropTypes.object,
  overrides: PropTypes.object,
  className: PropTypes.string,
  style: PropTypes.object,
  attrs: PropTypes.object
};

export const getJsonFromLayout = (props) => {
  const { layout, overrides, disallowedComponents } = props;

  const components = {
    // TODO: Clean-up "Art" eventually as part of the rethink getArtSlotInfo project
    // The idea is that Election, Video, Image (and maybe CustomHTML which
    // Image handles ok for now) will come across as their own distinct keys
    // in the layout/arrangement object. Video would still be messy for other reasons
    // (i. objects needed for web and jsonapp are much different and ii. videoData can
    // come from backing content) but Election and Image would be cleaner
    Art: () => {
      if (props.electionData) {
        const election = getElection(props);
        return {
          Art: !election
            ? undefined
            : {
                // NOTE: Add a markupComponent to map to Markup component keys
                // TODO: Clean-up eventually as part of the rethink getArtSlotInfo project
                markupComponent: "Election",
                ...election
              }
        };
      }
      if (props.olympicsData) {
        const olympics = getOlympics(props);
        return {
          Art: !olympics
            ? undefined
            : {
                // NOTE: Add a markupComponent to map to Markup component keys
                // TODO: Clean-up eventually as part of the rethink getArtSlotInfo project
                markupComponent: "Olympics",
                ...olympics
              }
        };
      }
      const slideshow = getSlideshow(props);
      if (slideshow) {
        return {
          Art: {
            // NOTE: Add a markupComponent to map to Markup component keys
            // TODO: Clean-up eventually as part of the rethink getArtSlotInfo project
            markupComponent: "Slideshow",
            ...slideshow
          }
        };
      }
      // NOTE: For when backing content is inline video and there is no alt art
      props.videoData = getVideoData(props);
      if (props.videoData) {
        const caption = getCaption(props);
        return {
          Art: {
            // NOTE: Add a markupComponent to map to Markup component keys
            // TODO: Clean-up eventually as part of the rethink getArtSlotInfo project
            markupComponent: "Video",
            videoData: props.videoData,
            overrides,
            caption,
            isAdmin: props.isAdmin
          }
        };
      }
      const image = getImage(props);
      return {
        Art: !image
          ? undefined
          : {
              // NOTE: Add a markupComponent to map to Markup component keys
              // TODO: Clean-up eventually as part of the rethink getArtSlotInfo project
              markupComponent: "Image",
              ...image
            }
      };
    },
    // NOTE: Established pattern is so clean
    Audio: () => ({ Audio: getAudio(props) }),
    Blurb: () => ({ Blurb: getMultiBlurb(props) }),
    CallToAction: () => ({
      CallToAction: getCallToAction(props)
    }),
    Count: () => ({ Count: getCount(props) }),
    Excerpt: () => ({ Excerpt: getExcerpt(props) }),
    Footnote: () => ({ Footnote: getFootnote(props) }),
    Headline: () => ({ Headline: getHeadline(props) }),
    Label: () => ({ Label: getCompoundLabel(props) }),
    Recipe: () => ({ Recipe: getRecipe(props) }),
    RelatedLinks: () => ({ RelatedLinks: getRelatedLinks(props) }),
    LiveTicker: () => ({ LiveTicker: getLiveTicker(props) }),
    Sigline: () => ({ Sigline: getSigline(props) }),
    TopperLabel: () => ({ TopperLabel: getTopperLabel(props) })
  };

  let json = Object.keys(layout).reduce((acc, position) => {
    layout[position].items.forEach((key) => {
      if (components[key] && !(disallowedComponents || []).includes(key)) {
        merge(acc, components[key](props));
      }
    });
    return acc;
  }, {});

  // NOTE: This default key is here cuz we once considered having different arrangements
  // per breakpoint and the apps expect this structure, though destructuring here.
  const { default: arrangement } = getSubComponentArrangements({
    layout,
    overrides,
    json
  });

  json = {
    arrangement,
    ...json
  };

  return json;
};

getJsonFromLayout.propTypes = {
  props: PropTypes.object
};
