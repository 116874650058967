import PropTypes from "prop-types";
import get from "lodash.get";
import set from "lodash.set";
import kebabcase from "lodash.kebabcase";
import snakecase from "lodash.snakecase";

import { getMedia } from "./LiveGraphic.helpers";

export const getOlympics = ({
  // content = {},
  overrides = {},
  olympicsData,
  isAdmin = false
}) => {
  const component = get(olympicsData, "component", "");
  if (!olympicsData || !component) return undefined;

  const {
    liveGraphicsContentConfig: {
      contentConfigValues: { id }
    },
    liveGraphicsUseScreenshot = false
  } = overrides;

  const hasLiveImages =
    !!get(olympicsData, "hasScreenshots", false) &&
    !!get(olympicsData, "liveImages", false);

  const useScreenshot =
    hasLiveImages &&
    (liveGraphicsUseScreenshot || get(olympicsData, "useScreenshot", false));
  const useScreenshotForApps =
    hasLiveImages &&
    (liveGraphicsUseScreenshot ||
      get(olympicsData, "useScreenshotForApps", false));
  const updateClientSide = isAdmin;
  const showErrorMessage = isAdmin;

  // NOTE: Structure liveImages for media object
  const media = getMedia(olympicsData, overrides, "cta.link.url");

  return {
    id,
    ...olympicsData,
    media,
    useScreenshot,
    useScreenshotForApps,
    updateClientSide,
    showErrorMessage
  };
};

export const groomOlympicsForJsonApp = ({ olympics }) => {
  const component = get(olympics, "component", undefined);
  if (!olympics || !component) return undefined;

  if (olympics?.media?.artPosition)
    olympics.media.artPosition = kebabcase(olympics.media.artPosition);
  if (olympics?.media?.artWidth)
    olympics.media.artWidth = kebabcase(olympics.media.artWidth);

  const dynamicReplacement = snakecase(`Olympics${component}`);

  const { id, useScreenshotForApps, query = {} } = olympics;
  const { numberOfRowsShownPerPage = 0 } = query;

  let limit = 5;
  const limitFromId = /.*(?:_|-)(\d+)(?:_|-|$)/;
  if (
    typeof numberOfRowsShownPerPage === "number" &&
    numberOfRowsShownPerPage >= 1
  ) {
    limit = Number.parseInt(numberOfRowsShownPerPage, 10);
  } else if (limitFromId.test(id)) {
    // NOTE: parse limit from id, where id could have values like:
    // medals-hp-3, medals_hp_3, medals-hp-3-notext, medals_hp_3_notext
    // If we ever add things like dates to the id, this could break.
    limit = Number.parseInt(limitFromId.exec(id)[1], 10);
  }

  const data = get(olympics, "data", []);
  if (data.length > limit) {
    set(olympics, "data", data.slice(0, limit));
  }

  delete olympics.query;
  delete olympics.queryParamString;

  return {
    media: {
      [dynamicReplacement]: useScreenshotForApps
        ? undefined
        : {
            ...olympics,
            liveImages: undefined,
            component: undefined,
            useScreenshot: undefined,
            useScreenshotForApps: undefined,
            updateClientSide: undefined,
            media: undefined,
            showErrorMessage: undefined
          },
      dynamicReplacement: useScreenshotForApps ? undefined : dynamicReplacement,
      ...get(olympics, "media", {})
    }
  };
};

groomOlympicsForJsonApp.propTypes = {
  olympics: PropTypes.object
};
