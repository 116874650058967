import { Fragment, useRef, useEffect, useState } from "react";
import PropTypes from "@arc-fusion/prop-types";
import { isHTMLFragment, isHTMLFile } from "./CustomHTML.helpers";
import Warning from "~/shared-components/Warning";
import logger from "~/spartan-homepage/logger";

/**
 * @param {string} s Your html string.
 * @returns string of HTML minus any script tags.
 */
function stripScripts(s) {
  const div = document.createElement("div");
  div.innerHTML = s;
  const scripts = div.getElementsByTagName("script");
  let i = scripts.length;
  // eslint-disable-next-line no-plusplus
  while (i--) {
    scripts[i].parentNode.removeChild(scripts[i]);
  }
  return div.innerHTML;
}

/**
 * @param {string} s Your html string.
 * @returns array of any script tags within the string
 */
function getScripts(s) {
  const div = document.createElement("div");
  div.innerHTML = s;
  const scripts = div.getElementsByTagName("script");
  return scripts;
}

const ImportHTMLFragment = ({ src, isAdmin }) => {
  const [innerHTML, setInnerHTML] = useState("");
  const [isError, setError] = useState(false);

  useEffect(() => {
    async function fetchData() {
      try {
        const stuff = await fetch(src);
        const data = await stuff.text();
        setInnerHTML(data);
      } catch (e) {
        logger.info("ImportHTMLFragment Error", { error: e });
        if (isAdmin) setError(true);
      }
    }

    fetchData();
  }, [src, isAdmin]);

  return isError ? (
    <Warning
      message="CustomHTML alternate art is invalid. Fix or remove it."
      level="warning"
    />
  ) : (
    <EmbeddedFragment __html={innerHTML} />
  );
};

ImportHTMLFragment.propTypes = {
  src: PropTypes.string,
  isAdmin: PropTypes.bool
};

const Scripts = ({ scripts }) => {
  const myRef = useRef();
  useEffect(() => {
    Array.from(scripts).forEach((script) => {
      const s = document.createElement("script");
      if (script.src) {
        s.src = script.src;
      }
      if (script.text) {
        s.text = script.text;
      }
      myRef.current.appendChild(s);
    });
  }, [scripts]);
  return <div ref={myRef} />;
};

Scripts.propTypes = {
  scripts: PropTypes.any
};

const EmbeddedFragment = ({ __html }) => {
  const innerHtml = stripScripts(__html);
  const scripts = getScripts(__html);

  return (
    <div className="art-slot custom-html" style={{ overflow: "hidden" }}>
      <div dangerouslySetInnerHTML={{ __html: innerHtml }} />
      <Scripts scripts={scripts} />
    </div>
  );
};

EmbeddedFragment.propTypes = {
  __html: PropTypes.string
};

const HTMLFragment = ({ __html }) => {
  return <EmbeddedFragment __html={__html} />;
};

HTMLFragment.propTypes = {
  __html: PropTypes.string
};

const CustomHTML = ({ html, warning, isAdmin }) => {
  const isFragment = isHTMLFragment(html);
  const isFile = isHTMLFile(html);
  if (!isFragment && !isFile) return null;

  return (
    <Fragment>
      {warning && <Warning message={warning} level="warning" />}
      {isFragment && <HTMLFragment __html={html} />}
      {isFile && <ImportHTMLFragment src={html} isAdmin={isAdmin} />}
    </Fragment>
  );
};

CustomHTML.propTypes = {
  html: PropTypes.string,
  warning: PropTypes.string,
  isAdmin: PropTypes.bool
};

export default CustomHTML;
