import PropTypes from "prop-types";
import { styled, theme } from "@washingtonpost/wpds-ui-kit";
import { getStylesFromSize } from "../_utilities/style-helpers.js";
import { toHoursAndMinutesFromMinutes } from "~/components/utilities/date-utility.js";

const RecipeBox = styled("div", {
  ...getStylesFromSize("blurb"),
  color: theme.colors.gray80,
  "> *": {
    "&:not(:last-child):after": {
      content: "|",
      display: "inline-block",
      marginLeft: theme.space["025"],
      marginRight: theme.space["050"]
    }
  },
  "[data-qa=star]": {
    color: theme.colors["mustard300-static"]
  }
});

const getDurationToShow = (totalTime) => {
  if (!totalTime) return undefined;
  const minutes = Math.round(totalTime / 60);
  return toHoursAndMinutesFromMinutes(minutes);
};

const getCourseToShow = (courses = []) => {
  const course = courses.length ? courses[0]?.description : undefined;
  if (!course) return undefined;
  return course.replace(/ Course/i, "");
};

const getRatingToShow = (rating = {}) => {
  const { value, count } = rating;
  if (!value) return undefined;
  const countToShow = count ? ` (${count})` : "";
  return `${value}${countToShow}`;
};

const Recipe = ({ totalTime, courses, rating }) => {
  const durationToShow = getDurationToShow(totalTime);
  const courseToShow = getCourseToShow(courses);
  const ratingToShow = getRatingToShow(rating);
  if (!durationToShow && !courseToShow && !ratingToShow) return null;
  return (
    <RecipeBox className="pb-xs">
      {durationToShow && <span>{durationToShow} </span>}
      {courseToShow && <span>{courseToShow}</span>}
      {ratingToShow && (
        <span>
          <span data-qa="star">★</span> {ratingToShow}
        </span>
      )}
    </RecipeBox>
  );
};

Recipe.propTypes = {
  totalTime: PropTypes.number,
  courses: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      description: PropTypes.string
    })
  ),
  rating: PropTypes.shape({
    type: PropTypes.oneOf(["star", "pinnocchio"]),
    value: PropTypes.number,
    count: PropTypes.number,
    max: PropTypes.number
  })
};

export default Recipe;
