import { Fragment } from "react";
import PropTypes from "prop-types";
import kebabcase from "lodash.kebabcase";
import { getClasses } from "@washingtonpost/front-end-utils";
import { styled, theme } from "@washingtonpost/wpds-ui-kit";
import { getStylesFromSize } from "../_utilities/style-helpers";
import { useAssemblerContext } from "~/shared-components/AssemblerContext";
import { useChainContext } from "~/shared-components/ChainContext";
import { iff } from "~/components/utilities/logic";
import getHeadlineToolbarItems from "./Headline.toolbar";

const fontStyleToWeightMap = {
  Bold: "bold",
  Light: "light"
};

const fontStyleMap = {
  Italic: "italic"
};

/*
 * @param {string} alignment -- an alignment, like "left" or "center"
 * @param {string} fontStyle -- The font style, like Bold or Italic
 * @param {string} size -- The headline size, like "Gargantuan (ALL CAPS)", etc.
 *
 * @return {object} -- css object suitable for passing to stitches
 */
const getHeadlineCss = (props) => {
  const {
    alignment = "left",
    fontStyle = "Bold",
    fontFamily = "Default",
    size = "Small"
  } = props;

  // NOTE: Only override non-defaults, See HeadlineStyled below
  return /Tiny/.test(size)
    ? {
        ...getStylesFromSize("tiny"),
        ...(fontStyleToWeightMap[fontStyle]
          ? { fontWeight: theme.fontWeights[fontStyleToWeightMap[fontStyle]] }
          : {}),
        textAlign: alignment
      }
    : {
        ...getStylesFromSize(kebabcase(size)),
        ...(/Ultra/.test(fontFamily)
          ? { fontFamily: theme.fonts.magazine }
          : {}),
        ...(fontStyleMap[fontStyle]
          ? { fontStyle: fontStyleMap[fontStyle] }
          : {}),
        ...(fontStyleToWeightMap[fontStyle]
          ? { fontWeight: theme.fontWeights[fontStyleToWeightMap[fontStyle]] }
          : {}),
        ...(fontStyleMap[fontStyle]
          ? { fontStyle: fontStyleMap[fontStyle] }
          : {}),
        textAlign: alignment
      };
  // END: stitches
};

export const HeadlineStyled = styled("h2", {
  position: "relative",
  fontWeight: theme.fontWeights.regular
});

/*
 * @param {string} alignment -- an alignment, like "left" or "center"
 *
 * @return {object} -- css object suitable for passing to stitches
 */
const getDeckCss = (props) => {
  const { alignment = "left" } = props;
  // NOTE: Only override non-defaults, See DeckStyled below
  return { textAlign: alignment };
};

export const DeckStyled = styled("div", {
  position: "relative",
  ...getStylesFromSize("deck")
});

const PrefixStyled = styled("span", {
  color: theme.colors.gray80,
  "&:after": {
    // content: " | ",
    content: "\uFFE8", // HALFWIDTH FORMS LIGHT VERTICAL
    color: theme.colors.gray100
  },
  variants: {
    isOpinionPrefix: {
      true: {
        "a:hover &": {
          color: theme.colors.gray80
        },
        color: theme.colors.gold60
      }
    }
  }
});

const Prefix = (props) => {
  const { prefix, isOpinionPrefix } = props;
  if (!prefix) return null;
  const variants = { isOpinionPrefix };
  return (
    <Fragment>
      <PrefixStyled {...variants}>{prefix}</PrefixStyled>
      <wbr />
    </Fragment>
  );
};

Prefix.propTypes = {
  prefix: PropTypes.string,
  isOpinionPrefix: PropTypes.bool
};

const Headline = (props) => {
  const { deck, text, type, url } = props;

  const { EditableProperty } = useAssemblerContext();
  const { useDesktopOrdering } = useChainContext();

  const wrapperClasses = "headline relative gray-darkest pb-xs";

  const headlineClasses = getClasses("", {
    "blt blt-outside blt-diamond blt-gray": type === "bullet"
  });
  const css = getHeadlineCss(props);

  const deckClasses = deck ? "pt-xs" : "";
  const deckCss = getDeckCss(props);
  const deckLinkClasses = "hover-inherit";

  const path = iff(useDesktopOrdering, "headline", "headlineMobileOnly");

  const toolbarItems = getHeadlineToolbarItems(props);

  const headlineText = (
    <EditableProperty
      path={path}
      tag="span"
      value={text}
      menuItems={toolbarItems}
    />
  );

  const deckText = (
    <EditableProperty
      path={"deck"}
      tag="span"
      value={deck}
      className={deckLinkClasses}
    />
  );

  return (
    <div className={wrapperClasses}>
      <HeadlineStyled className={headlineClasses} css={css}>
        {url ? (
          <a
            data-pb-local-content-field="web_headline" // Websked Chrome extension
            href={url}
          >
            <Prefix {...props} />
            {headlineText}
          </a>
        ) : (
          headlineText
        )}
      </HeadlineStyled>
      {deck && (
        <DeckStyled className={deckClasses} css={deckCss}>
          {url ? (
            <a
              className={deckLinkClasses}
              data-pb-local-content-field="web_headline" // Websked Chrome extension
              href={url}
            >
              {deckText}
            </a>
          ) : (
            deckText
          )}
        </DeckStyled>
      )}
    </div>
  );
};

Headline.propTypes = {
  alignment: PropTypes.string,
  deck: PropTypes.string,
  fontStyle: PropTypes.string,
  fontFamily: PropTypes.string,
  hide: PropTypes.bool,
  isAdmin: PropTypes.bool,
  setEntity: PropTypes.func,
  size: PropTypes.string,
  text: PropTypes.string,
  type: PropTypes.string,
  url: PropTypes.string,
  value: PropTypes.string
};

export default Headline;
